<template>
	<div class="w-100">
		<!-- Preferred Pronoun Options -->
		<Radios
			:values="pronouns"
			validName="Preferred Pronouns"
			name="Preferred Pronouns"
			getter="getPreferredPronoun"
			updateMethod="updatePreferredPronoun"
			title="What are your preferred pronouns?"
		/>

		<!-- Preferred Pronoun Other Option -->
		<form-row v-if="otherPreferredPronoun">
			<form-input
				block
				rules="required"
				validName="Other Preferred Pronoun"
				name="otherPreferredPronoun"
				dataStore="form"
				updateMethod="updateOtherPreferredPronoun"
				label="Other preferred pronoun"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Radios from '@/components/form/radio/group'
import formInput from '@/components/form/form-input'
import formRow from '@/components/form/form-row'

export default {
	components: {
		Radios,
		formRow,
		formInput
	},
	data() {
    return {
			otherPreferredPronoun: null,
			pronouns: [
				{
					name: "He/Him",
					id: "he-him"
				}, {
					name: "She/Her",
					id: "she-her"
				}, {
					name: "They/Them",
					id: "they-them"
				}, {
					name: "I don't know",
					id: "dont-know-pronoun"
				}, {
					name: "Prefer not to say",
					id: "prefer-not-to-say-pronoun"
				}, {
					name: "Other/Multiple",
					id: "other-pronoun"
				}
			]
    }
  },
	computed: {
		...mapGetters([
			'getPreferredPronoun',
			'getOtherPreferredPronoun'
		])
	},
	watch: {
    getPreferredPronoun(value) {
      if (value === 'Other/Multiple') {
				this.otherPreferredPronoun = true
				this.$announcer.polite('The form has been updated.')
      } else {
				this.otherPreferredPronoun = false
				this.$announcer.polite('The form has been updated.')
				
				// Clear other preferred pronoun if switching between radio buttons.
				if ( this.getOtherPreferredPronoun.length ) {
					this.$store.dispatch('clearOtherPreferredPronoun')
				}
			}
    },
	},
	methods: {
		checkState() {
			// Check stored state
			if (this.getPreferredPronoun.length && this.getPreferredPronoun === 'Other/Multiple') {
				this.otherPreferredPronoun = true
			} 
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
