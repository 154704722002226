<template>
  <div>
		<page-header
			title="About you"
		>
			<template v-slot:content>
				OK, now we're going to ask you some more detailed questions. This will be used to create your Brook record. We will not tell anyone what you tell us today, unless we are worried about you or someone else. If you want to know more about any of this, read our <a class="text-light text-underline" href="https://www.brook.org.uk/privacy-and-confidentiality/" target="_blank">privacy and confidentiality page</a>..
			</template>
		</page-header>
		<page-container>
			<form
				autocomplete="off"
				@submit.prevent="validateForm"
			>
				<!-- Your Details Fields -->
				<your-details />

				<form-row>
					<!-- Ethnicity Options-->
					<ethnicity />
					<!-- Sexuality Options -->
					<sexuality />
					<!-- Assigned Sex Options -->
					<assigned-sex />
					<!-- Preferred Pronoun Options -->
					<preferred-pronoun />
				</form-row>

				<form-submit title="Continue to more about you"/>
			</form>
		</page-container>
  </div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Mixins
import scrollToError from '@/mixins/scroll-to-error'
import mixpanelTracking from '@/mixins/mixpanel'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import formRow from '@/components/form/form-row'
import formSubmit from '@/components/form/form-submit'

// Steps Components
import yourDetails from '@/components/steps/about-you/your-details'
import ethnicity from '@/components/steps/about-you/ethnicity'
import sexuality from '@/components/steps/about-you/sexuality'
import assignedSex from '@/components/steps/about-you/assigned-sex'
import preferredPronoun from '@/components/steps/about-you/preferred-pronoun'

export default {
	mixins: [scrollToError, mixpanelTracking],
	components: {
		pageHeader,
		pageContainer,
		formRow,
		formSubmit,
		yourDetails,
		ethnicity,
		sexuality,
		assignedSex,
		preferredPronoun
	},
	computed: {
		...mapGetters([
			'getFirstName',
			'getLastName',
			'getDateOfBirth',
			'getFullPostcode',
			'getContactNumber',
			'getEthnicityGroup',
			'getEthnicity',
			'getOtherEthnicity',
			'getSexAssignedAtBirth'
		]),
		postcode() {
			// Postcode validation
			if (!this.getFullPostcode.length) {
				return 'Please enter your full postcode'
			}
			if ( this.getFullPostcode.length && this.$GLOBALS.REGEX_POSTCODE.test(this.getFullPostcode)) {
				return ''
			} else {
				return 'Please enter a valid UK postcode'
			}
		},
		ethnicityValidation() {
			return (
				!!this.getEthnicityGroup && !!this.getEthnicity ||
				this.getEthnicityGroup.includes('Prefer not to say')
			)
		}
	},
	methods: {
		async validateForm() {
			// Clear loading state
			this.$store.commit('loading', false)

			// Match ethnicities
			const matchEtnicities = "Any other white background" || "Any other mixed background" || "Any other Asian background" || "Any other Black background" || "Any other ethnic group"

			// Check form validations
			if (
				!!this.getFirstName &&
				!!this.getLastName &&
				!!this.getDateOfBirth &&
				!!this.getFullPostcode &&
				!!this.$GLOBALS.REGEX_POSTCODE.test(this.getFullPostcode) &&
				!!this.getContactNumber &&
				!!this.ethnicityValidation &&
				!!this.getSexAssignedAtBirth
			) {
				// Check other conditions
				if ( this.getEthnicity.includes(matchEtnicities) && !this.getOtherEthnicity.length ) {
					// Set errrors
					await this.$store.commit('updateErrors', {
						...this.getErrors,
						otherEthnicity: this.getEthnicity.includes(matchEtnicities) && !this.getOtherEthnicity.length ? 'Please enter your ethnic group' : ''
					})
					
					// Scroll to error
					await this.scrollToError()	
					// Cancel submission
					return
				}

				// Set stepB to complete & clear errors
				await this.$store.commit('completeStepB', true)
				await this.$store.dispatch('clearErrors')

				// Proceed to next step
				await this.$router.push(
					this.$GLOBALS.PATH_MORE_ABOUT_YOU
				)
			} else {
				// Set errrors
				await this.$store.commit('updateErrors', {
					...this.getErrors,
					firstName: !this.getFirstName.length ? 'Please enter your first name' : '',
					lastName: !this.getLastName.length ? 'Please enter your last name' : '',
					fullPostcode: this.postcode,
					dateOfBirth: !this.getDateOfBirth.length ? 'Please enter your date of birth' : '',
					contactNumber: !this.getContactNumber.length && !this.$GLOBALS.REGEX_PHONE.test(this.getContactNumber) ? 'Please enter a valid phone number' : '',
					ethnicityGroup: !this.getEthnicityGroup.length ? 'Please select you ethnic background' : '',
					ethnicity: this.getEthnicityGroup.length && !this.getEthnicity.length ? 'Please select you ethnic background' : '',
					otherEthnicity: this.getEthnicity.includes(matchEtnicities) && !this.getOtherEthnicity.length ? 'Please enter your ethnic group' : '',
					assignedSex: !this.getSexAssignedAtBirth.length ? 'Please select the sex were you assigned at birth' : '',
				})

				// Scroll to error
				await this.scrollToError()
			}
		}
	}
}
</script>
