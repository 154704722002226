<template>
	<div class="w-100">
		<!-- Sexuality Options -->
		<Radios
			:values="sexualities"
			name="Sexualities"
			getter="getSexuality"
			updateMethod="updateSexuality"
			title="How would you describe your sexuality?"
		/>

		<!-- Sexuality Other Option -->
		<form-row v-if="otherSexuality">
			<form-input
				block
				name="otherSexuality"
				dataStore="form"
				updateMethod="updateOtherSexuality"
				label="Other Sexuality"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Radios from '@/components/form/radio/group'
import formInput from '@/components/form/form-input'
import formRow from '@/components/form/form-row'

export default {
	components: {
		Radios,
		formRow,
		formInput
	},
	data() {
    return {
			otherSexuality: null,
			sexualities: [
				{
					name: "Straight",
					id: "straight"
				}, {
					name: "Gay",
					id: "gay"
				}, {
					name: "Bi",
					id: "bi"
				}, {
					name: "Not sure",
					id: "not-sure"
				}, {
					name: "Prefer not to say",
					id: "na-sexual-orientation"
				}, {
					name: "Other",
					id: "other-sexual-orientation"
				}
			]
    }
  },
	computed: {
		...mapGetters([
			'getSexuality',
			'getOtherSexuality'
		])
	},
	watch: {
    getSexuality(value) {
      if (value === 'Other') {
				this.otherSexuality = true
				this.$announcer.polite('The form has been updated.')
      } else {
				this.otherSexuality = false
				this.$announcer.polite('The form has been updated.')
				
				// Clear other sexuality if switching between radio buttons.
				if ( this.getOtherSexuality.length ) {
					this.$store.dispatch('clearOtherSexuality')
				}
			}
    },
	},
	methods: {
		checkState() {
			// Check stored state
			if (this.getSexuality.length && this.getSexuality === 'Other') {
				this.otherSexuality = true
			} 
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
