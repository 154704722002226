<template>
	<form-row title="Your details">
		<!-- First name -->
		<form-input
			required
			name="firstName"
			dataStore="form"
			updateMethod="updateFirstName"
			label="What is your first name?"
		/>
		<!-- Last name -->
		<form-input
			required
			name="lastName"
			dataStore="form"
			updateMethod="updateLastName"
			label="What is your last name?"
		/>
		<!-- Preferred name -->
		<form-input
			name="preferredName"
			dataStore="form"
			updateMethod="updatePreferredName"
			label="What would you like us to call you?"
		/>
		<!-- Date of birth -->
		<form-date
			required
			mode="single"
			placeholder="Select date"
			name="dateOfBirth"
			dataStore="form"
			updateMethod="updateDateOfBirth"
			label="What is your date of birth?"
		/>
		<!-- Postcode -->
		<form-input
			required
			postcode
			name="fullPostcode"
			dataStore="form"
			updateMethod="updateFullPostcode"
			label="What is your full postcode?"
		/>
		<!-- Mobile number -->
		<form-input
			required
			type="tel"
			name="contactNumber"
			dataStore="form"
			updateMethod="updateContactNumber"
			label="What is your mobile number?"
			description="We might want to send you a message about future appointments or test results."
		/>
	</form-row>
</template>

<script>
// Form Components
import formInput from '@/components/form/form-input'
import formDate from '@/components/form/form-date'
import formRow from '@/components/form/form-row'

export default {
	components: {
		formInput,
		formDate,
		formRow,
	}
}
</script>