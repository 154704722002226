<template>
	<!-- Assigned Sex Options -->
	<Radios
		required
		:values="sexes"
		name="assignedSex"
		getter="getSexAssignedAtBirth"
		updateMethod="updateSexAssignedAtBirth"
		title="What sex were you assigned at birth?"
	/>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Radios from '@/components/form/radio/group'

export default {
	components: {
		Radios
	},
	data() {
    return {
			sexes: [
				{
					name: "Male",
					id: "male"
				}, {
					name: "Female",
					id: "female"
				}, {
					name: "Not sure",
					id: "not-sure-sex"
				}, {
					name: "Prefer not to say",
					id: "na-sex"
				}
			]
    }
	},
	computed: {
		...mapGetters([
			'getSexAssignedAtBirth'
		])
	},
	methods: {
		checkState() {
			// Check stored state
			if ( this.getSexAssignedAtBirth.length ) {
				this.selected = this.getSexAssignedAtBirth
			}
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
