<template>
	<div class="w-100">
		<!-- Ethnicity Options -->
		<Radios
			required
			:values="ethnicities"
			name="ethnicityGroup"
			getter="getEthnicityGroup"
			updateMethod="updateEthnicityGroup"
			title="How would you describe yourself?"
		/>

		<!-- Ethnicity: White -->
		<Radios
			required
			v-if="getEthnicityGroup === 'White'"
			:values="white"
			name="ethnicity"
			getter="getEthnicity"
			updateMethod="updateEthnicity"
			title="Which group best describes your White background?"
		/>

		<!-- Ethnicity: Mixed -->
		<Radios
			required
			v-if="getEthnicityGroup === 'Mixed'"
			:values="mixed"
			name="ethnicity"
			getter="getEthnicity"
			updateMethod="updateEthnicity"
			title="Which group best describes your Mixed background?"
		/>

		<!-- Ethnicity: Asian -->
		<Radios
			required
			v-if="getEthnicityGroup === 'Asian / Asian British'"
			:values="asian"
			name="ethnicity"
			getter="getEthnicity"
			updateMethod="updateEthnicity"
			title="Which group best describes your Asian / Asian British background?"
		/>

		<!-- Ethnicity: Black -->
		<Radios
			required
			v-if="getEthnicityGroup === 'Black or Black British'"
			:values="black"
			name="ethnicity"
			getter="getEthnicity"
			updateMethod="updateEthnicity"
			title="Which group best describes your Black or Black British background?"
		/>

		<!-- Ethnicity: Other -->
		<Radios
			required
			v-if="getEthnicityGroup === 'Other ethnic groups'"
			:values="other"
			name="ethnicity"
			getter="getEthnicity"
			updateMethod="updateEthnicity"
			title="Which group best describes your Other background?"
		/>

		<!-- Ethnicity Other Option -->
		<form-row v-if="otherEthnicity">
			<form-input
				required
				block
				name="otherEthnicity"
				dataStore="form"
				updateMethod="updateOtherEthnicity"
				label="Other Ethnic Group"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import formInput from '@/components/form/form-input'
import formRow from '@/components/form/form-row'
import Radios from '@/components/form/radio/group'

export default {
	components: {
		Radios,
		formRow,
		formInput
	},
	data() {
    return {
			otherEthnicity: null,
			ethnicities: [
				{
					name: "White",
					id: "white"
				}, {
					name: "Mixed",
					id: "mixed"
				}, {
					name: "Asian / Asian British",
					id: "asian-british"
				}, {
					name: "Black or Black British",
					id: "black-black-british"
				}, {
					name: "Other ethnic groups",
					id: "other-ethnicity"
				}, {
					name: "Prefer not to say",
					id: "na-ethnicity"
				}
			],
			white: [
				{
					name: "British",
					id: "british"
				}, {
					name: "Irish",
					id: "irish"
				}, {
					name: "Any other white background",
					id: "white-other"
				},
			],
			mixed: [
				{
					name: "White and Black Caribbean",
					id: "white-and-black-caribbean"
				}, {
					name: "White and Black African",
					id: "white-and-black-african"
				}, {
					name: "White and Asian",
					id: "white-and-asian"
				}, {
					name: "Any other mixed background",
					id: "mixed-other"
				},
			],
			asian: [
				{
					name: "Indian",
					id: "indian"
				}, {
					name: "Pakistani",
					id: "pakistani"
				}, {
					name: "Bangladeshi",
					id: "bangladeshi"
				}, {
					name: "Any other Asian background",
					id: "asian-other"
				},
			],
			black: [
				{
					name: "Caribbean",
					id: "caribbean"
				}, {
					name: "African",
					id: "african"
				}, {
					name: "Any other Black background",
					id: "black-other"
				}
			],
			other: [
				{
					name: "Chinese",
					id: "chinese"
				}, {
					name: "Any other ethnic group",
					id: "other-other"
				}
			]
    }
  },
	computed: {
		...mapGetters([
			'getEthnicityGroup',
			'getEthnicity',
			'getOtherEthnicity'
		])
	},
	watch: {
    getEthnicity(value) {
			// Check ethnicities if other options are set
      if (
				value === 'Any other white background' ||
				value === 'Any other mixed background' ||
				value === 'Any other Asian background' ||
				value === 'Any other Black background' ||
				value === 'Any other ethnic group'
			) {
				this.otherEthnicity = true
				this.$announcer.polite('The form has been updated.')
      } else {
				this.otherEthnicity = false
				this.$announcer.polite('The form has been updated.')
				
				// Clear other ethnicity if switching between radio buttons.
				if ( this.getOtherEthnicity.length ) {
					this.$store.dispatch('clearOtherEthnicity')
				}
			}
		},
		getEthnicityGroup(value) {
			// Clear ethnicities if ethnicity group is changed
			if (value) {
				this.$store.dispatch('clearEthnicity')
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if (
					this.getEthnicity.length && 
					(
						this.getEthnicity === 'Any other white background' ||
						this.getEthnicity === 'Any other mixed background' ||
						this.getEthnicity === 'Any other Asian background' ||
						this.getEthnicity === 'Any other Black background' ||
						this.getEthnicity === 'Any other ethnic group'
					)
				) {
				this.otherEthnicity = true
			} 
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
